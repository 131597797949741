body {
    background-color: #fff;
}

/* COLORS */
.colorMainAccent {
    color: #00bed7;
}
.colorAccent2 {
    color: #503143;
}
.colorAccent3 {
    color: #ff9789;
}
.colorBlendWhite {
    color: rgba(255,255,255,0.50);
}
.colorBlendDark {
    color: rgba(0,0,0,0.50);
}

/* GLOBALS */
a {
    text-decoration: none;
    color: #79ad9f;
}
a:hover {
    color: #fff;
}
input[type='range']::-webkit-slider-thumb {
    background: #503143;   
}


.sectionPadding {
    padding: 10vh 0 10vh 0;
}
.customDivider {  
    margin: 20px 20% 0px 20%;      
}    
#overview {
    background-color: #f7f7f7;
}
.introTitle {
    font-weight: bold;
    text-shadow: 3px 3px 10px rgba(0,0,0,0.60);  
    padding: 10px    
}

.introText {
    font-weight: bold;  
    background-color: rgba(0,0,0,0.75);
    padding: 10px;
    border-radius: 10px;
}
.sectionHeadIcons {
    margin-left: 20px;
}

#customRange1 {
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */
    width: 8px;
    height: 175px;
    padding: 0 5px;
}

.contactContainer {
    background-color: #000;
    border-bottom: 2px solid #fff;
}
.headerFix {
    margin-top: 57px;
}
.force-left {
    text-align: left !important;
}
.introContainer {
    background-image: url("images/webcolor2.jpg");
    background-size: cover;    
    background-position: center;
    background-attachment: fixed;
    height: 550px;
}

.exploreButton {
    margin: 0 auto;
    padding: 20px; 
}

.headIcons {
    float: right;
    margin: 0 10px 0 10px;
}

.skillBox {
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #503143;
    color: #503143;
    margin: 5px;
    float: left;
}

.workSkillBox {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #fff;
    color: #fff;
    margin: 5px;    
    display: inline-block;
    font-size: 0.75rem;
}

.opacity20 {
    opacity: 30%;
}

.opacity50 {
    opacity: 50%;
}

.infoIcons:hover {
    cursor: pointer;
}

.popoverHeader {
    background-color: #00bed7;
    color: #fff;
}

#work {
    background-color: #503143;
}

.workSectionOne {    
    overflow: hidden;     
}
.sectionSlider .slide1 {
    transition-duration: 500ms;
    position: relative;    
    transition-timing-function: ease-in-out;
}
.workExamplesWrapper {
    padding: 10px 0 30px 0;
}
.workExampleImages {
    max-width: 100%;    
    position: relative;
    left: 0;
    padding: 10px 0 10px 0;
}
   
.right-animation {
    animation: enter-right 1s 1;
}
.left-animation {
    animation: enter-left 1s 1;
}

/* ANIMATIONS */
@keyframes enter-right {
	0% {
		transform: translate(800px, 0);
	}
	50% {
		transform: translate(0px, 0);
	}
}
@keyframes enter-left {
	0% {
		transform: translate(-800px, 0);
	}
	50% {
		transform: translate(0px, 0);
	}
}


